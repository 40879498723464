export default {
  computed: {
    addMarginToMain() {
      return this.$date().isBetween(
        '2021-08-24 00:00:00',
        '2021-09-07 23:59:59'
      )
    }
  },
  methods: {
    formatAmount(amount) {
      return parseFloat(String(amount || 0))
        .toFixed(2)
        .replace('.', ',')
    }
  }
}
