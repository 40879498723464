<template>
  <div class="diet-configuration__container">
    <div class="diet-configuration__content-wrapper">
      <div class="meals-grid diet-length-row">
        <div class="datepicker-wrapper">
          <!--          <h4 class="labelNormal text-center">data pierwszej dostawy:</h4>-->

          <div class="box-container">
            <div class="datepicker-container">
              <datepicker
                :language="language.pl"
                :disabled-dates="disabledAtDays"
                v-model="deliveryDate"
                :monday-first="true"
                class="diet-configuration__datepicker"
              ></datepicker>
            </div>
          </div>

          <div
            class="position-relative mt-10 animated-linear"
            :class="{ 'mt-80': lockedDeliveryDate !== null }"
          >
            <div
              id="first_delivery_popup_hide"
              :class="{
                first_delivery_popup_hide: firstDeliveryNote === false,
                'first_delivery_popup_hide opacity0': firstDeliveryNote === true
              }"
              @click="firstDeliveryNote = !firstDeliveryNote"
            >
              <span class="icon_info icon_info_hide">?</span>
              <h3 class="p_info_hide">
                Od czego zależy termin pierwszej dostawy?
              </h3>
            </div>
            <div
              class="popup_main_box_wrapper"
              v-if="firstDeliveryNote"
              @click="firstDeliveryNote = false"
            ></div>
            <div
              class="popup_main_box m-b-20"
              id="popup_main_box"
              v-if="firstDeliveryNote"
            >
              <span class="icon_info">?</span>
              <div class="main_box_content">
                <div class="closer" @click="firstDeliveryNote = false">
                  <span>X</span>
                </div>
                <h3 class="p_info_hide">
                  Od czego zależy termin pierwszej dostawy?
                </h3>
                <div class="hr"></div>
                <p id="popup_p" style="display: block">
                  Sugerowany przez nas termin pierwszej dostawy ustalany jest na
                  podstawie liczby zaplanowanych na dany dzień diet. Naszym
                  priorytetem jest dostarczanie jedzenia najwyższej jakości,
                  dlatego okazjonalnie możemy wydłużyć termin realizacji Twojej
                  pierwszej diety.
                </p>
              </div>
            </div>
          </div>

          <!--          <div class="first-delivery__container">-->
          <!--            <div-->
          <!--              class="first-delivery__info-text"-->
          <!--              @click="firstDeliveryNote = !firstDeliveryNote"-->
          <!--            >-->
          <!--              <div><img src="/img/q_icon.png" alt="" /></div>-->
          <!--              <p class="">Od czego zależy termin pierwszej dostawy?</p>-->
          <!--            </div>-->

          <!--            <div class="first-delivery__modal">-->
          <!--              <div class="">-->
          <!--                <span @click="firstDeliveryNote = false">X</span>-->
          <!--                <p>-->
          <!--                  Sugerowany przez nas termin pierwszej dostawy ustalany jest na-->
          <!--                  podstawie liczby zaplanowanych na dany dzień diet. Naszym-->
          <!--                  priorytetem jest dostarczanie jedzenia najwyższej jakości,-->
          <!--                  dlatego okazjonalnie możemy wydłużyć termin realizacji Twojej-->
          <!--                  pierwszej diety.-->
          <!--                </p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>

        <div class="diet-length-wrapper">
          <!--          <h4 class="labelNormal text-center">podaj ilość dni:</h4>-->

          <div class="box-container">
            <div class="diet-length__container">
              <div class="diet-length__input-wrapper">
                <img
                  src="/img/minus.png"
                  alt=""
                  @click="--inputValue"
                  class="decrement"
                />

                <input
                  type="number"
                  id="day"
                  name="numOfDeliveries"
                  v-model="inputValue"
                  @blur="dietLengthLeaved"
                />

                <img
                  src="/img/plus.png"
                  alt=""
                  @click="++inputValue"
                  class="increment"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            class="box-container checkbox-container__meals weekends-included__container"
          >
            <input
              id="weekendsIncludedCheckbox"
              type="checkbox"
              v-model="weekendsIncluded"
            />
            <label for="weekendsIncludedCheckbox" class="checkbox-label__meals">
              <span>Dieta bez weekendów</span>
            </label>
          </div>
        </div>
      </div>

      <div class="diet-name-row">
        <div>
          <h4 class="">Podaj nazwę diety:</h4>
          <div class="box-container">
            <div class="diet-name__input-wrapper">
              <input type="text" v-model="name" maxlength="32" />
            </div>
          </div>
          <div class="text-center text-error">
            <p>{{ $store.getters.error('name') }}</p>
          </div>
        </div>

        <div>
          <h4 class="">Kod rabatowy:</h4>
          <div class="box-container">
            <div class="discount-code__input-wrapper">
              <div>
                <input
                  type="text"
                  name="discountValue"
                  :readonly="
                    $store.getters.getConfiguratorDiscountCode !== null
                  "
                  v-model="discount"
                />
                <button
                  v-if="$store.getters.getConfiguratorDiscountCode"
                  disabled
                  class=""
                  type="button"
                >
                  aktywny
                </button>
                <button v-else @click="checkDiscount" class="" type="button">
                  aktywuj
                </button>
              </div>
            </div>
          </div>
          <div class="box-container">
            <p v-if="discountError" class="alert alert-danger">
              {{ discountError }}
            </p>
            <p
              v-else-if="$store.getters.getConfiguratorDayCost.discountMessage"
              class="alert alert-warning"
            >
              {{ $store.getters.getConfiguratorDayCost.discountMessage }}
            </p>
            <p
              v-else-if="
                $store.getters.getConfiguratorDiscountCodeType ===
                DISCOUNT_CODE_TYPE_AMOUNT()
              "
              class="alert alert-success"
            >
              Rabat
              {{
                formatAmount($store.getters.getConfiguratorDiscountCodeValue)
              }}
              zł
            </p>
            <p
              v-else-if="
                $store.getters.getConfiguratorDiscountCodeType ===
                DISCOUNT_CODE_TYPE_PERCENTAGE()
              "
              class="alert alert-success"
            >
              Rabat
              {{ $store.getters.getConfiguratorDiscountCodeValue }}%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="row">-->
  <!--    <div v-if="!createOrderOnClick">-->
  <!--      <div class="clearfix">-->
  <!--        <div class="row">-->
  <!--          <div class="col-xs-12">-->
  <!--            <h3>Ma być dostarczany przez (liczba dni):</h3>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      <div class="clearfix days">-->
  <!--        <div class="plg-ni_program_spinner">-->
  <!--          <div-->
  <!--            class="days-element spinner-button plg-ni_program_spinner-prev"-->
  <!--            @click="&#45;&#45;inputValue"-->
  <!--          >-->
  <!--            <span class="decrement">-</span>-->
  <!--          </div>-->

  <!--          <div class="days-element">-->
  <!--            <input-->
  <!--              type="number"-->
  <!--              class="plg-ni_program_spinner-value plg-ni_program_configurator-daysSpinner"-->
  <!--              id="day"-->
  <!--              name="numOfDeliveries"-->
  <!--              v-model="inputValue"-->
  <!--              @blur="dietLengthLeaved"-->
  <!--            />-->
  <!--          </div>-->

  <!--          <div-->
  <!--            class="days-element spinner-button plg-ni_program_spinner-next"-->
  <!--            @click="++inputValue"-->
  <!--          >-->
  <!--            <span class="increment">+</span>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div v-else>-->
  <!--      <div class="row">-->
  <!--        <div-->
  <!--          class="col-lg-3 col-sm-6 m-b-20"-->
  <!--          style="z-index: 1"-->
  <!--          @click="unsetDateErrors"-->
  <!--        >-->
  <!--          <h4 class="labelNormal text-center">Data pierwszej dostawy:</h4>-->
  <!--          <datepicker-->
  <!--            :language="language.pl"-->
  <!--            :disabled-dates="disabledAtDays"-->
  <!--            v-model="deliveryDate"-->
  <!--            :monday-first="true"-->
  <!--          ></datepicker>-->

  <!--          <div-->
  <!--            class="subTitle text-danger text-center plg-ni_order_register_configurator-firstDeliveryAt-error"-->
  <!--          >-->
  <!--            {{ $store.getters.error('firstDeliveryAt') }}-->
  <!--          </div>-->
  <!--          <div-->
  <!--            class="subTitle text-danger text-center plg-ni_order_register_configurator-pricelist-error"-->
  <!--          >-->
  <!--            {{ $store.getters.error('priceList') }}-->
  <!--          </div>-->
  <!--          <div-->
  <!--            class="subTitle text-danger text-center plg-ni_order_register_configurator-order-error"-->
  <!--          >-->
  <!--            {{ $store.getters.error('order') }}-->
  <!--          </div>-->
  <!--          <div-->
  <!--            id="secondPopup"-->
  <!--            class="intersectionWarning plg-ni_order_register_configurator-intersectionWarning locked-date-popup mt-10"-->
  <!--            :class="{ 'popup-show': lockedDeliveryDate !== null }"-->
  <!--          >-->
  <!--            <div-->
  <!--              class="intersectionWarning__inner plg-ni_order_register_configurator-inner"-->
  <!--            >-->
  <!--              Na dzień <strong>{{ lockedDeliveryDate }}</strong> <br />-->
  <!--              masz jeszcze aktywną dietę!-->
  <!--            </div>-->
  <!--            <button-->
  <!--              id="closeError"-->
  <!--              type="button"-->
  <!--              class="intersectionWarning__close plg-ni_order_register_configurator-close"-->
  <!--              @click="lockedDeliveryDate = null"-->
  <!--            >-->
  <!--              <i class="fa fa-times"></i>-->
  <!--            </button>-->
  <!--          </div>-->

  <!--          <div-->
  <!--            class="position-relative mt-10 animated-linear"-->
  <!--            :class="{ 'mt-80': lockedDeliveryDate !== null }"-->
  <!--          >-->
  <!--            <div-->
  <!--              id="first_delivery_popup_hide"-->
  <!--              :class="{-->
  <!--                first_delivery_popup_hide: firstDeliveryNote === false,-->
  <!--                'first_delivery_popup_hide opacity0': firstDeliveryNote === true-->
  <!--              }"-->
  <!--              @click="firstDeliveryNote = !firstDeliveryNote"-->
  <!--            >-->
  <!--              <span class="icon_info icon_info_hide">?</span>-->
  <!--              <h3 class="p_info_hide">-->
  <!--                Od czego zależy termin pierwszej dostawy?-->
  <!--              </h3>-->
  <!--            </div>-->
  <!--            <div-->
  <!--              class="popup_main_box_wrapper"-->
  <!--              v-if="firstDeliveryNote"-->
  <!--              @click="firstDeliveryNote = false"-->
  <!--            ></div>-->
  <!--            <div-->
  <!--              class="popup_main_box m-b-20"-->
  <!--              id="popup_main_box"-->
  <!--              v-if="firstDeliveryNote"-->
  <!--            >-->
  <!--              <span class="icon_info">?</span>-->
  <!--              <div class="main_box_content">-->
  <!--                <div class="closer" @click="firstDeliveryNote = false">-->
  <!--                  <span>X</span>-->
  <!--                </div>-->
  <!--                <h3 class="p_info_hide">-->
  <!--                  Od czego zależy termin pierwszej dostawy?-->
  <!--                </h3>-->
  <!--                <div class="hr"></div>-->
  <!--                <p id="popup_p" style="display: block">-->
  <!--                  Sugerowany przez nas termin pierwszej dostawy ustalany jest na-->
  <!--                  podstawie liczby zaplanowanych na dany dzień diet. Naszym-->
  <!--                  priorytetem jest dostarczanie jedzenia najwyższej jakości,-->
  <!--                  dlatego okazjonalnie możemy wydłużyć termin realizacji Twojej-->
  <!--                  pierwszej diety.-->
  <!--                </p>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="separate separate3"></div>-->
  <!--        <div-->
  <!--          id="blur2"-->
  <!--          class="col-lg-6 col-sm-6 m-b-20"-->
  <!--          :class="{ 'blur-768-and-down': firstDeliveryNote === true }"-->
  <!--        >-->
  <!--          <h4 class="labelNormal text-center">Wybierz liczbę dni:</h4>-->
  <!--          <div class="clearfix days">-->
  <!--            <div class="plg-ni_program_spinner">-->
  <!--              <div-->
  <!--                class="days-element spinner-button plg-ni_program_spinner-prev"-->
  <!--                @click="&#45;&#45;inputValue"-->
  <!--              >-->
  <!--                <span class="decrement">-</span>-->
  <!--              </div>-->

  <!--              <div class="days-element days-element__input">-->
  <!--                <input-->
  <!--                  type="number"-->
  <!--                  class="plg-ni_program_spinner-value plg-ni_program_configurator-daysSpinner"-->
  <!--                  id="day"-->
  <!--                  name="numOfDeliveries"-->
  <!--                  v-model="inputValue"-->
  <!--                  @blur="dietLengthLeaved"-->
  <!--                />-->
  <!--                <div-->
  <!--                  class="subTitle text-danger text-center plg-ni_order_register_configurator-dietLength-error unsuccess__text"-->
  <!--                >-->
  <!--                  {{ $store.getters.error('dietLength') }}-->
  <!--                </div>-->
  <!--                <div-->
  <!--                  class="subTitle text-danger text-center plg-ni_order_register_configurator-days-error unsuccess__text"-->
  <!--                >-->
  <!--                  {{ $store.getters.error('days') }}-->
  <!--                </div>-->
  <!--              </div>-->

  <!--              <div-->
  <!--                class="days-element spinner-button plg-ni_program_spinner-next"-->
  <!--                @click="++inputValue"-->
  <!--              >-->
  <!--                <span class="increment">+</span>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->

  <!--          <div-->
  <!--            class="subTitle text-danger text-center plg-ni_order_register_configurator-weekendsIncluded-error"-->
  <!--          >-->
  <!--            {{ $store.getters.error('weekendsIncluded') }}-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="separate3"></div>-->
  <!--        <div-->
  <!--          id="blur3"-->
  <!--          class="col-lg-3 m-b-10 m-t-55 minWidthCenter dietwithoutweekends diet-without-weekends__checkbox-wrapper"-->
  <!--          :class="{ 'blur-1200-and-down': firstDeliveryNote === true }"-->
  <!--        >-->
  <!--          <label-->
  <!--            id="withoutWeekends"-->
  <!--            class="diet-checkbox diet-without-weekends__checkbox"-->
  <!--          >-->
  <!--            <input type="checkbox" v-model="weekendsIncluded" />-->
  <!--            <span id="wwspan" class="box"-->
  <!--              ><i class="fa fa-check" aria-hidden="true"></i-->
  <!--            ></span>-->
  <!--          </label>-->
  <!--          <p class="diet-without-weekends__checkbox-label">Dieta bez weekendów</p>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="separate2"></div>-->
  <!--      <div-->
  <!--        id="row-blur"-->
  <!--        class="row"-->
  <!--        :class="{ 'blur-all': firstDeliveryNote === true }"-->
  <!--      >-->
  <!--        <div class="col-sm-6 m-b-20">-->
  <!--          <h4 class="labelNormal text-center">Podaj nazwę diety:</h4>-->
  <!--          <div class="plg-ni_ui_lettersCounter">-->
  <!--            <input-->
  <!--              type="text"-->
  <!--              v-model="name"-->
  <!--              class="form-control text-center plg-ni_ui_lettersCounter-input plg-ni_order_register_configurator-dietName"-->
  <!--              maxlength="32"-->
  <!--            />-->
  <!--            <p class="subTitle text-muted text-center">-->
  <!--              Po nazwie diety rozpoznasz torbę ze swoim jedzeniem-->
  <!--            </p>-->
  <!--            <div-->
  <!--              class="subTitle text-danger text-center plg-ni_order_register_configurator-name-error"-->
  <!--            >-->
  <!--              {{ $store.getters.error('name') }}-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="col-sm-6 m-b-20">-->
  <!--          <h4 class="labelNormal text-center">Kod rabatowy:</h4>-->
  <!--          <div class="input-group">-->
  <!--            <input-->
  <!--              type="text"-->
  <!--              name="discountValue"-->
  <!--              :readonly="$store.getters.getConfiguratorDiscountCode !== null"-->
  <!--              class="form-control plg-ni_order_register_configurator-discountCode"-->
  <!--              v-model="discount"-->
  <!--            />-->

  <!--            <span class="input-group-btn">-->
  <!--              <button-->
  <!--                @click="checkDiscount"-->
  <!--                :disabled="$store.getters.getConfiguratorDiscountCode !== null"-->
  <!--                class="btn btn-custom plg-ni_order_register_configurator-discountActivate"-->
  <!--                type="button"-->
  <!--              >-->
  <!--                Aktywuj-->
  <!--              </button>-->
  <!--            </span>-->
  <!--          </div>-->
  <!--          <div-->
  <!--            class="subTitle text-danger text-center plg-ni_order_register_configurator-name-error"-->
  <!--          >-->
  <!--            {{ $t(discountError) }}-->
  <!--          </div>-->
  <!--          <p-->
  <!--            v-if="$store.getters.getConfiguratorDiscountCode !== null"-->
  <!--            class="plg-ni_order_register_configurator-discountSuccess subTitle text-success text-center"-->
  <!--          >-->
  <!--            <strong>Kod rabatowy aktywowany</strong>-->
  <!--          </p>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import * as types from '../../store/mutations_types'
import * as actionTypes from '../../store/actions_types'
import ConfiguratorApi from '../../services/api/configurator'
import Datepicker from 'vuejs-datepicker'
import { pl } from 'vuejs-datepicker/dist/locale'
import debounce from '../../helpers/debounce'
import {
  DISCOUNT_CODE_TYPE_AMOUNT,
  DISCOUNT_CODE_TYPE_PERCENTAGE
} from '@/consts/discountCodeType'
import MarginMixin from '@/helpers/mixins'

export default {
  props: {
    createOrderOnClick: {
      type: Boolean,
      default: false
    }
  },
  mixins: [MarginMixin],
  components: { Datepicker },
  computed: {
    inputValue: {
      get: function () {
        return this.$store.getters.getConfiguratorDietLength
      },
      set: function (value) {
        value = Math.abs(parseInt(value))

        if (value < 1) {
          value = 1
        }
        if (isNaN(value)) {
          value = null
        }
        if (value > 100) {
          value = 100
        }

        if (this.value !== value) {
          this.value = value
        }
      }
    },
    // maxLength() {
    //   let now = this.$date(new Date())
    //   let end = this.$date(this.deliveryDate)
    //   let duration = this.$date.duration(end.diff(now))
    //   let days = parseInt(duration.asDays())
    //
    //   let max = 150 - days
    //
    //   return max > 100 ? 100 : max
    // },
    discount: {
      get: function () {
        return this.$store.getters.getConfiguratorDiscountCodeText
      },
      set: function (value) {
        this.$store.commit(types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_TEXT, value)
      }
    },
    discountError: {
      get: function () {
        return this.$store.getters.getConfiguratorDiscountCodeError
      },
      set: function (value) {
        this.$store.commit(types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_ERROR, value)
      }
    },
    deliveryDate: {
      get: function () {
        return this.$store.getters.getConfiguratorFirstDeliveryAt
      },
      set: function (value) {
        value = new this.$date(value).format('YYYY-MM-DD')
        this.$store.commit(types.UPDATE_CONFIGURATOR_FIRST_DELIVERY_AT, value)

        this.$store.dispatch(
          actionTypes.FETCH_DIET_PRICE,
          this.$store.getters.getParamsForCalculateDietPrice
        )
      }
    },
    name: {
      get: function () {
        return this.$store.getters.getConfiguratorName
      },
      set: function (value) {
        this.$store.commit(types.UPDATE_CONFIGURATOR_NAME, value)
      }
    }
  },
  data: function () {
    return {
      disabledAtDays: {
        to: new Date(),
        from: this.$date().add(150, 'days').toDate(),
        dates: [],
        days: []
      },
      value: null,
      firstDeliveryConfig: {
        disabledCustomDays: [],
        disabledDays: [],
        firstDeliveryAt: null
      },
      weekendsIncluded: true, // This value should be negative as in store, this must be duplicate bc computed not work good,
      firstDeliveryNote: false,
      lockedDeliveryDate: null,
      language: {
        pl: pl
      }
    }
  },
  mounted: function () {
    if (this.createOrderOnClick === true) {
      this.loadFirstDeliveryConfig()
    }
  },
  watch: {
    weekendsIncluded(value) {
      this.$store.commit(types.UPDATE_CONFIGURATOR_WEEKENDS_INCLUDED, !value)
      this.updateDisabledDates()
      this.$store.dispatch(
        actionTypes.FETCH_DIET_PRICE,
        this.$store.getters.getParamsForCalculateDietPrice
      )
    },
    deliveryDate(value) {
      if (this.createOrderOnClick === true && value === null) {
        this.loadFirstDeliveryConfig()
      }
      if (this.createOrderOnClick === true && value !== null) {
        this.checkDeliveryDate()
      }
    },
    value(n, o) {
      if (n !== o) {
        this.changeValue(n, o)
      }
    }
  },
  methods: {
    DISCOUNT_CODE_TYPE_PERCENTAGE() {
      return DISCOUNT_CODE_TYPE_PERCENTAGE
    },
    DISCOUNT_CODE_TYPE_AMOUNT() {
      return DISCOUNT_CODE_TYPE_AMOUNT
    },
    changeValue: debounce(function (value, oldValue) {
      this.$store.dispatch(actionTypes.CHANGE_CONFIGURATOR_DIET_LENGTH, value)
      this.$store
        .dispatch(
          actionTypes.FETCH_DIET_PRICE,
          this.$store.getters.getParamsForCalculateDietPrice
        )
        .then(() => {
          if (this.createOrderOnClick === true) {
            this.checkDeliveryDate()
          }
          this.$forceUpdate()
        })
    }, 250),
    unsetDateErrors() {
      if (this.$store.getters.error('firstDeliveryAt')) {
        this.$store.commit(types.ORDER_CHANGE_ERRORS, [])
        this.loadFirstDeliveryConfig()
      }
    },
    updateDisabledDates() {
      if (this.$store.getters.getConfiguratorWeekendsIncluded === true) {
        this.disabledAtDays.days = [].concat(
          this.firstDeliveryConfig.disabledDays
        )
      } else {
        this.disabledAtDays.days = [0, 6].concat(
          this.firstDeliveryConfig.disabledDays
        )
      }

      this.validDate()
    },
    checkDiscount() {
      if (typeof this.discount !== 'undefined' && this.discount !== null) {
        this.discountError = null
        ConfiguratorApi.getDiscountInfo(this.discount)
          .then(res => {
            if (res.data.error_message !== null) {
              this.discountError = res.data.error_message
            } else {
              this.$store.commit(
                types.UPDATE_CONFIGURATOR_DISCOUNT_CODE,
                res.data.id
              )
              this.$store.commit(
                types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_VALUE,
                res.data.discount
              )
              this.$store.commit(
                types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_TYPE,
                res.data.discount_type
              )
              this.$store.commit(
                types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_REFERER,
                res.data.referer
              )
              this.$store.dispatch(
                actionTypes.FETCH_DIET_PRICE,
                this.$store.getters.getParamsForCalculateDietPrice
              )
            }
          })
          .catch()
      }
    },
    validDate() {
      if (this.deliveryDate === null) {
        return
      }

      let deliveryDate = this.$date(this.deliveryDate)
      let dayOfWeek = deliveryDate.day()
      let date = new Date(this.deliveryDate)

      let dateDisabled = false
      for (let i = 0; i < this.disabledAtDays.dates.length; i++) {
        if (this.disabledAtDays.dates[i].getTime() === date.getTime()) {
          dateDisabled = true
          break
        }
      }
      if (
        this.disabledAtDays.days.includes(dayOfWeek) ||
        dateDisabled === true
      ) {
        this.deliveryDate = deliveryDate.add(1, 'd').toDate()
        this.validDate()
      }
    },
    loadFirstDeliveryConfig() {
      ConfiguratorApi.getFirstDeliveryDate().then(response => {
        this.firstDeliveryConfig = response.data
        if (this.firstDeliveryConfig.firstDeliveryAt) {
          this.disabledAtDays.to = new Date(
            this.firstDeliveryConfig.firstDeliveryAt
          )
          this.deliveryDate = new Date(this.firstDeliveryConfig.firstDeliveryAt)
          this.disabledAtDays.dates = this.firstDeliveryConfig.disabledCustomDays.map(
            el => {
              return new Date(el)
            }
          )
        } else {
          this.disabledAtDays.to = new Date('2050-01-01')
        }

        this.updateDisabledDates()
      })
      this.weekendsIncluded = !this.$store.getters
        .getConfiguratorWeekendsIncluded
      this.updateDisabledDates()

      if (
        this.discount === '' &&
        this.$store.getters.getConfiguratorRefererCode !== null
      ) {
        this.discount = this.$store.getters.getConfiguratorRefererCode
        this.$store.commit(types.UPDATE_CONFIGURATOR_REFERER_CODE, null)
        this.checkDiscount()
      }
    },
    dietLengthLeaved() {
      if (this.inputValue === null) {
        this.inputValue = 1
      }
    },
    checkDeliveryDate() {
      if (this.inputValue !== null) {
        ConfiguratorApi.checkDate(this.deliveryDate, this.inputValue).then(
          res => {
            this.lockedDeliveryDate = res.data

            if (this.lockedDeliveryDate === '') {
              this.lockedDeliveryDate = null
            }
          }
        )
      }
    }
  }
}
</script>
