import * as types from '../mutations_types'
import * as actionTypes from '../actions_types'
import dayjs from '@/plugins/dayjs'
import ConfiguratorApi from '../../services/api/configurator'
import sentry from '../../sentry'

const DEFAULT_SET_BY_PACKAGE_POSTION = JSON.parse(
  process.env.VUE_APP_DEFAULT_CONFIGURATOR
)
const DEFAULT_PACKAGE = 2

function DEFAULT_SET_BY_ORDER_POSTIONS(selectedPackagePosition) {
  if (
    !Object.keys(DEFAULT_SET_BY_PACKAGE_POSTION).includes(
      selectedPackagePosition + ''
    )
  ) {
    return { variant: 1, basket: 1 }
  }
  return DEFAULT_SET_BY_PACKAGE_POSTION[selectedPackagePosition]
}

let fetchMealsPriceTimeout = null
let fetchSelectedPackageTimeout = null
let firstDefaultConfig = null

function defaultConfig() {
  return {
    dietLength: 10,
    basket: null,
    basketMeals: [],
    package: null,
    variant: null,
    dayCost: {
      beforeDiscount: 0,
      afterDiscount: 0,
      discountMessage: '',
      total: 0,
    },
    weekendsIncluded: true,
    name: '',
    firstDeliveryAt: null,
    discountCode: null,
    discountCodeType: null,
    discountCodeValue: null
  }
}

function initialState() {
  return {
    configurator: defaultConfig(),
    mealsPrice: {},
    discountCodeStore: {
      code: '',
      referer: null,
      error: null
    },
    options: {
      packages: [],
      variants: [],
      baskets: [],
      showVariantHashtags: false
    },
    availableAddonMeals: {},
    selectedAddonMeals: {},
    optionsLoaded: false,
    sortedMealTypeNames: [],
    refererCode: null,
    selectedDiet: null,
    mealTypes: []
  }
}

function dataMapper(string) {
  const mapper = {
    1000: 10,
    1250: 11,
    1500: 12,
    1750: 13,
    2000: 14,
    2550: 15,
    optymalna: 22,
    wegetarianska: 23,
    slim: 24,
    'mniej-glutenu': 25,
    wegeryba: 26,
    'niski-ig': 27,
    basic: 2,
    plus: 3,
    'super+': 4,
    rs: 6
  }

  return mapper[string.toLowerCase()]
}

const state = initialState()

const actions = {
  [actionTypes.FETCH_CONFIGURATION]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.configuratorOptionsHasBeenLoaded) {
        resolve()
        return
      }
      commit(types.CONFIGURATOR_OPTIONS_LOADED)

      ConfiguratorApi.getConfig().then(
        successResponse => {
          commit(types.SET_OPTIONS, successResponse.data)
          resolve()
        },
        failedResponse => {
          sentry.captureException(failedResponse)
        }
      )
    })
  },
  [actionTypes.FETCH_DIET_PRICE]({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      ConfiguratorApi.getDietPrice(params).then(
        successResponse => {
          commit(types.UPDATE_CONFIGURATOR_DAY_COST, successResponse.data)
          resolve()
        },
        failedResponse => {
          sentry.captureException(failedResponse)
        }
      )
    })
  },
  [actionTypes.FETCH_SELECTED_DIET]({ commit, getters }, { id }) {
    return new Promise((resolve, reject) => {
      clearTimeout(fetchSelectedPackageTimeout)
      fetchMealsPriceTimeout = setTimeout(() => {
        ConfiguratorApi.getSelectedPackage(id).then(
          successResponse => {
            commit(types.UPDATE_SELECTED_DIET, successResponse.data.package)
            resolve(successResponse.data)
          },
          failedResponse => {
            sentry.captureException(failedResponse)
          }
        )
      }, 250)
    })
  },
  [actionTypes.FETCH_MEALS_PRICE]({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      clearTimeout(fetchMealsPriceTimeout)
      fetchMealsPriceTimeout = setTimeout(() => {
        const date = new dayjs().format('YYYY-MM-DD')
        ConfiguratorApi.getMealsPrice(date).then(
          successResponse => {
            commit(types.UPDATE_MEALS_PRICE, successResponse.data)
            resolve()
          },
          failedResponse => {
            sentry.captureException(failedResponse)
          }
        )
      }, 250)
    })
  },
  [actionTypes.CHANGE_CONFIGURATOR_BASKET](
    { commit, getters, dispatch },
    params
  ) {
    // ID wybranych starych typów posiłków
    let oldTypesIds = getters.getConfiguratorBasketMealsOptions
      .filter(el => {
        return getters.getConfiguratorBasketMeals.includes(el.id)
      })
      .map(el => {
        return el.mealType.id
      })

    // ID dostępnych starych typów posiłków
    let oldAllTypesIds = getters.getConfiguratorBasketMealsOptions.map(el => {
      return el.mealType.id
    })

    let excludedMealTypesIds = []

    oldAllTypesIds.forEach(oldTypeId => {
      if (!oldTypesIds.includes(oldTypeId)) {
        excludedMealTypesIds.push(oldTypeId)
      }
    })

    commit(types.UPDATE_CONFIGURATOR_BASKET, params)

    let newSelectedMeals = []

    getters.getConfiguratorBasketMealsOptions.forEach(newBasketMeal => {
      if (!excludedMealTypesIds.includes(newBasketMeal.mealType.id)) {
        newSelectedMeals.push(newBasketMeal.id)
      }
    })

    dispatch(actionTypes.CHANGE_CONFIGURATOR_MEAL_TYPES, newSelectedMeals)
    dispatch(
      actionTypes.FETCH_DIET_PRICE,
      getters.getParamsForCalculateDietPrice
    )
  },
  [actionTypes.CHANGE_CONFIGURATOR_MEAL_TYPES](
    { commit, dispatch, getters },
    params
  ) {
    commit(types.UPDATE_CONFIGURATOR_MEAL_TYPES, params)
    dispatch(
      actionTypes.FETCH_DIET_PRICE,
      getters.getParamsForCalculateDietPrice
    )
  },
  [actionTypes.CHANGE_CONFIGURATOR_PACKAGE](
    { commit, dispatch, getters },
    params
  ) {
    commit(types.UPDATE_CONFIGURATOR_PACKAGE, params)
    dispatch(
      actionTypes.CHANGE_CONFIGURATOR_VARIANT,
      getters.getConfiguratorVariantOptions.find(el => {
        return (
          el.position ===
          DEFAULT_SET_BY_ORDER_POSTIONS(
            getters.getConfiguratorPackageObject.order
          ).variant
        )
      }).id
    )

    let variantId = getters.getConfiguratorVariant
    let BreakException = {}

    try {
      if (getters.getConfiguratorDisabledVariants.includes(variantId)) {
        getters.getConfiguratorVariantOptions.forEach(variant => {
          if (!getters.getConfiguratorDisabledVariants.includes(variant.id)) {
            dispatch(actionTypes.CHANGE_CONFIGURATOR_VARIANT, variant.id)
            throw BreakException
          }
        })
      }
    } catch (e) {
      if (e !== BreakException) throw e
    }

    dispatch(
      actionTypes.FETCH_DIET_PRICE,
      getters.getParamsForCalculateDietPrice
    )
  },
  [actionTypes.CHANGE_CONFIGURATOR_VARIANT](
    { commit, getters, dispatch },
    params
  ) {
    commit(types.UPDATE_CONFIGURATOR_VARIANT, params)

    let basketOptions = getters.getConfiguratorBasketOptions.map(el => el.id)
    if (
      !basketOptions.includes(getters.getConfiguratorBasket) &&
      getters.getConfiguratorBasketOptions.length > 0
    ) {
      dispatch(actionTypes.CHANGE_CONFIGURATOR_BASKET, basketOptions[0])
      dispatch(
        actionTypes.CHANGE_CONFIGURATOR_MEAL_TYPES,
        getters.getConfiguratorBasketOptions[0].basketMeals.map(el => el.id)
      )
    }
  },
  [actionTypes.CHANGE_CONFIGURATOR_DIET_LENGTH]({ commit }, params) {
    commit(types.UPDATE_CONFIGURATOR_DIET_LENGTH, params)
  },
  [actionTypes.RESET_CONFIGURATOR_FIRST_CORRECT_STATE]({ commit }) {
    commit(types.RESET_CONFIGURATOR_DATA)
  },
  async [actionTypes.SET_DATA_FROM_LP]({ commit, getters, dispatch }, payload) {
    await dispatch(actionTypes.FETCH_CONFIGURATION)
    if (payload.type) {
      dispatch(
        actionTypes.CHANGE_CONFIGURATOR_PACKAGE,
        dataMapper(payload.type)
      )
    }

    if (payload.calorie) {
      dispatch(
        actionTypes.CHANGE_CONFIGURATOR_BASKET,
        dataMapper(payload.calorie)
      )
    }

    if (payload.dietId) {
      dispatch(
        actionTypes.CHANGE_CONFIGURATOR_VARIANT,
        parseInt(payload.dietId, 10)
      )
    } else {
      if (payload.diet) {
        dispatch(
          actionTypes.CHANGE_CONFIGURATOR_VARIANT,
          dataMapper(payload.diet)
        )
      }
    }

    if (payload.dietLength) {
      dispatch(actionTypes.CHANGE_CONFIGURATOR_DIET_LENGTH, payload.dietLength)
    }

    if (payload.meals.length) {
      const meals = getters.getConfiguratorBasketMealsOptions
        .filter(meal => payload.meals.includes(meal.mealType.id))
        .map(meal => meal.id)

      dispatch(actionTypes.CHANGE_CONFIGURATOR_MEAL_TYPES, meals)
    } else {
      dispatch(
        actionTypes.CHANGE_CONFIGURATOR_MEAL_TYPES,
        getters.getConfiguratorBasketMealsOptions.map(el => el.id)
      )
    }
  },
  [actionTypes.CHANGE_ADDON_MEALS]({ state, dispatch }, payload) {
    state.selectedAddonMeals = payload
    // dispatch(actionTypes.CHANGE_ADDON_MEALS, payload)
  }
}

const getters = {
  getConfiguratorDietLength: state => state.configurator.dietLength,
  getSelectedDietId: state => {
    return state.selectedDiet
  },
  getConfiguratorBasket: state => state.configurator.basket,
  getMealTypeNames: state => state.sortedMealTypeNames,
  getMealTypes: state => state.mealTypes,
  getConfiguratorBasketOptions: (state, getters) => {
    let baskets = state.options.baskets.filter(basket => {
      let variantIds = basket.variants.map(variant => variant.id)
      return variantIds.includes(getters.getConfiguratorVariant)
    })

    return baskets.sort((el1, el2) => {
      return el1.position - el2.position
    })
  },
  getConfiguratorBasketMeals: state => state.configurator.basketMeals,
  getConfiguratorBasketMealsOptions: (state, getters) => {
    let basket = getters.getConfiguratorBasketOptions.find(el => {
      return el.id === getters.getConfiguratorBasket
    })

    if (typeof basket !== 'undefined') {
      return basket.basketMeals.filter(el => {
        return el.mealType.position !== 99
      })
    }

    return []
  },
  getConfiguratorSelectedMeals: (state, getter) => {
    return state.selectedAddonMeals
  },
  getConfiguratorAvailableMeals: (state, getter) => {
    return state.availableAddonMeals
  },
  getConfiguratorMealsPrice: (state, getter) => {
    return state.mealsPrice
  },
  getConfiguratorDisabledVariants: (state, getters) => {
    let disabledVariants = []
    let selectedPackage = getters.getConfiguratorPackage
    let selectedBasket = getters.getConfiguratorBasket

    if (selectedPackage === null || selectedBasket === null) {
      return state.options.variants.map(el => el.id)
    }

    let packageObject = state.options.packages.find(el => {
      return el.id === selectedPackage
    })
    let basketObject = state.options.baskets.find(el => {
      return el.id === selectedBasket
    })

    state.options.variants
      .map(el => el.id)
      .forEach(variantId => {
        if (
          !(
            packageObject.variants
              .map(el => {
                return el.id
              })
              .indexOf(variantId) > -1
          )
        ) {
          disabledVariants.push(variantId)
        }
        if (
          !(
            basketObject.variants
              .map(el => {
                return el.id
              })
              .indexOf(variantId) > -1
          )
        ) {
          disabledVariants.push(variantId)
        }
      })

    return disabledVariants.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
  },
  getConfiguratorPackage: state => state.configurator.package,
  getConfiguratorPackageOptions: state => state.options.packages,
  getConfiguratorPackageObject: (state, getters) =>
    state.options.packages.find(el => {
      return el.id === getters.getConfiguratorPackage
    }),
  getConfiguratorVariant: state => state.configurator.variant,
  getConfiguratorVariantObject: (state, getters) =>
    state.options.variants.find(el => {
      return el.id === getters.getConfiguratorVariant
    }),
  getConfiguratorVariantOptions: state => state.options.variants,
  getConfiguratorDayCost: state => state.configurator.dayCost,
  getConfiguratorRefererCode: state => state.configurator.refererCode,
  getConfiguratorMgmCode: state => state.configurator.mgmCode,
  getConfiguratorWeekendsIncluded: state => state.configurator.weekendsIncluded,
  getConfiguratorName: state => state.configurator.name,
  getConfiguratorFirstDeliveryAt: state =>
    new dayjs(state.configurator.firstDeliveryAt).format('YYYY-MM-DD'),
  getConfiguratorDiscountCode: state => state.configurator.discountCode,
  getConfiguratorDiscountCodeValue: state => {
    return state.configurator.discountCodeValue
  },
  getConfiguratorDiscountCodeType: state => {
    return state.configurator.discountCodeType
  },
  getConfiguratorDiscountCodeText: state => state.discountCodeStore.code,
  getConfiguratorDiscountCodeReferer: state => {
    if (
      state.discountCodeStore.referer === '' ||
      typeof state.discountCodeStore.referer === 'undefined'
    ) {
      return null
    }
    return state.discountCodeStore.referer
  },
  getConfiguratorDiscountCodeError: state => state.discountCodeStore.error,
  getConfigurator: state => state.configurator,
  configuratorOptionsHasBeenLoaded: state => state.optionsLoaded,
  getParamsForCalculateDietPrice: state => {
    let basket = null

    for (let i = 0; i < state.options.baskets.length; i++) {
      if (state.options.baskets[i].id === state.configurator.basket) {
        basket = state.options.baskets[i]
        break
      }
    }

    if (basket === null) {
      return
    }
    let sizeIds = []
    sizeIds = basket.basketMeals
      .filter(function (basketMeal) {
        return state.configurator.basketMeals.indexOf(basketMeal.id) > -1
      })
      .map(basketMeal => {
        return basketMeal.size.id
      })

    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    let yyyy = today.getFullYear()

    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }
    today = yyyy + '-' + mm + '-' + dd

    return {
      sizeIds: sizeIds,
      package: state.configurator.package,
      startDate:
        state.configurator.firstDeliveryAt !== null
          ? new dayjs(state.configurator.firstDeliveryAt).format('YYYY-MM-DD')
          : today,
      dietLength: state.configurator.dietLength,
      discountCode: state.configurator.discountCode,
      weekendsIncluded: state.configurator.weekendsIncluded
    }
  }
}

const mutations = {
  [types.SET_OPTIONS](state, options) {
    state.options = options

    state.options.packages = state.options.packages.sort((el1, el2) => {
      return el1.order - el2.order
    })

    state.options.baskets = state.options.baskets
      .sort((el1, el2) => {
        return el1.position - el2.position
      })
      .map(el => {
        el.basketMeals.sort((el1, el2) => {
          return el1.mealType.position - el2.mealType.position
        })
        return el
      })

    const lastBasketIndex = state.options.baskets.length - 1

    state.mealTypes = state.options.baskets[
      lastBasketIndex
    ].basketMeals.map(({ mealType: { name, id } }) => ({ name, id }))
    state.sortedMealTypeNames = state.options.baskets[
      lastBasketIndex
    ].basketMeals.map(({ mealType: { name } }) => name)

    state.selectedAddonMeals = state.sortedMealTypeNames.reduce(
      (prev, curr) => {
        return {
          ...prev,
          [curr]: []
        }
      },
      {}
    )

    state.options.variants = state.options.variants
      .map(el => {
        el.showDescription = false
        return el
      })
      .sort((el1, el2) => {
        return el1.position - el2.position
      })

    if (state.configurator.package === null) {
      state.configurator.package = state.options.packages.reduce(
        (a, c) => {
          return c.order === DEFAULT_PACKAGE || null === a.id ? c : a
        },
        { id: null }
      ).id
    }

    let packageObject = state.options.packages.find(el => {
      return el.id === state.configurator.package
    })

    if (state.mealsPrice.packages) {
      state.availableAddonMeals = state.sortedMealTypeNames.reduce(
        (prev, curr) => {
          return {
            ...prev,
            [curr]: state.options.baskets.reduce(
              (populatedBasketMeals, basketMeal) => {
                const mealType = basketMeal.basketMeals.find(
                  ({ mealType: { name } }) => name === curr
                )

                if (!mealType) return populatedBasketMeals

                const isAlreadyAdded = populatedBasketMeals.some(
                  alreadyAddedMealSize =>
                    alreadyAddedMealSize.name === mealType.size.name
                )

                if (isAlreadyAdded) {
                  return populatedBasketMeals
                }
                // mealType.size.sizeId = mealType.size.id
                mealType.size.mealTypeId = state.mealTypes.find(
                  ({ name }) => name === curr
                ).id
                mealType.size.price = state.mealsPrice.packages
                  .filter(({ sizeId }) => sizeId === mealType.size.id)
                  .reduce((prev, curr) => {
                    return {
                      ...prev,
                      [curr.packageId]: curr.price
                    }
                  }, {})

                // .find(({ sizeId, packageId }) => (sizeId === mealType.size.id && packageId === packageObject.id)).price

                return [...populatedBasketMeals, mealType.size]
              },
              []
            )
          }
        },
        {}
      )
    }

    if (state.configurator.variant === null) {
      let variant = state.options.variants.find(el => {
        return (
          el.position ===
          DEFAULT_SET_BY_ORDER_POSTIONS(packageObject.order).variant
        )
      })

      if (variant) {
        state.configurator.variant = variant.id
      }
    }

    if (state.configurator.basket === null) {
      let baskets = state.options.baskets.filter(basket => {
        let variantIds = basket.variants.map(variant => variant.id)
        return variantIds.includes(state.configurator.variant)
      })

      if (baskets.length > 0) {
        state.configurator.basket = baskets.find(el => {
          return (
            el.position ===
            DEFAULT_SET_BY_ORDER_POSTIONS(packageObject.order).basket
          )
        }).id
        state.configurator.basketMeals = baskets
          .find(el => {
            return (
              el.position ===
              DEFAULT_SET_BY_ORDER_POSTIONS(packageObject.order).basket
            )
          })
          .basketMeals.map(el => {
            return el.id
          })
      }
    }

    if (firstDefaultConfig === null && state.configurator.basket !== null) {
      firstDefaultConfig = JSON.parse(JSON.stringify(state.configurator))
    }
  },
  [types.RESET_ADD_MEALS_STATE](state) {
    const lastElementIndex = state.options.baskets.length - 1
    state.sortedMealTypeNames = state.options.baskets[
      lastElementIndex
    ].basketMeals.map(({ mealType: { name } }) => name)

    state.selectedAddonMeals = state.sortedMealTypeNames.reduce(
      (prev, curr) => {
        return {
          ...prev,
          [curr]: []
        }
      },
      {}
    )
  },
  [types.SET_CONFIGURATOR_DATA](state, data) {
    state.configurator = data
  },
  [types.RESET_CONFIGURATOR_DATA](state) {
    state.configurator =
      firstDefaultConfig === null
        ? defaultConfig()
        : Object.assign({}, firstDefaultConfig)
    state.discountCodeStore = {
      code: '',
      referer: null,
      error: null
    }
  },
  [types.UPDATE_ADDON_MEALS](state, data) {
    state.selectedAddonMeals = data
  },

  [types.UPDATE_CONFIGURATOR_DATA](state, field, newValue) {
    state.configurator[field] = newValue
  },
  [types.CONFIGURATOR_OPTIONS_LOADED](state) {
    state.optionsLoaded = true
  },
  [types.UPDATE_CONFIGURATOR_BASKET](state, value) {
    state.configurator.basket = value
  },
  [types.UPDATE_CONFIGURATOR_MEAL_TYPES](state, value) {
    state.configurator.basketMeals = value
  },
  [types.UPDATE_CONFIGURATOR_PACKAGE](state, value) {
    state.configurator.package = value
  },
  [types.UPDATE_CONFIGURATOR_VARIANT](state, value) {
    state.configurator.variant = value
  },
  [types.UPDATE_CONFIGURATOR_DIET_LENGTH](state, value) {
    state.configurator.dietLength = value
  },
  [types.UPDATE_CONFIGURATOR_DAY_COST](state, value) {
    state.configurator.dayCost = value
  },
  [types.UPDATE_MEALS_PRICE](state, value) {
    state.mealsPrice = value
  },
  [types.UPDATE_SELECTED_DIET](state, value) {
    state.selectedDiet = value
  },
  [types.UPDATE_CONFIGURATOR_WEEKENDS_INCLUDED](state, value) {
    state.configurator.weekendsIncluded = value
  },
  [types.UPDATE_CONFIGURATOR_NAME](state, value) {
    state.configurator.name = value
  },
  [types.UPDATE_CONFIGURATOR_FIRST_DELIVERY_AT](state, value) {
    state.configurator.firstDeliveryAt = value
  },
  [types.UPDATE_CONFIGURATOR_DISCOUNT_CODE](state, value) {
    state.configurator.discountCode = value
  },
  [types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_VALUE](state, value) {
    state.configurator.discountCodeValue = value
  },
  [types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_TYPE](state, value) {
    state.configurator.discountCodeType = value
  },
  [types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_TEXT](state, value) {
    state.discountCodeStore.code = value
  },
  [types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_REFERER](state, value) {
    state.discountCodeStore.referer = value
  },
  [types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_ERROR](state, value) {
    state.discountCodeStore.error = value
  },
  [types.UPDATE_CONFIGURATOR_REFERER_CODE](state, value) {
    state.configurator.refererCode = value
  },
  [types.UPDATE_CONFIGURATOR_MGM_CODE](state, value) {
    state.configurator.mgmCode = value
  },
  [types.RESET_CONFIGURATOR](state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
