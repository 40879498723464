/** Reset **/
export const RESET_AUTH = 'RESET_AUTH'
export const RESET_CONFIGURATOR = 'RESET_CONFIGURATOR'
export const RESET_ORDER = 'RESET_ORDER'
export const RESET_DIET = 'RESET_DIET'
export const RESET_TAGS = 'RESET_TAGS'
export const RESET_USER = 'RESET_USER'
export const RESET_CALENDAR = 'RESET_CALENDAR'
export const RESET_MONEYBOX = 'RESET_MONEYBOX'

/** Auth **/
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const UPDATE_REFRESH_TOKEN = 'UPDATE_REFRESH_TOKEN'
export const UPDATE_IMPERSONATOR_TOKEN = 'UPDATE_IMPERSONATOR_TOKEN'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'

export const AUTH_ERROR_CHANGE = 'AUTH_ERROR_CHANGE'
export const LOADING_START = 'LOADING_START'
export const LOADING_STOP = 'LOADING_STOP'
export const LOADING_MUTE = 'LOADING_MUTE'
export const LOADING_UNMUTE = 'LOADING_UNMUTE'
export const UPDATE_REDIRECT_AFTER_LOGIN = 'UPDATE_REDIRECT_AFTER_LOGIN'

/** Configurator **/
export const SET_OPTIONS = 'SET_OPTIONS'
export const SET_CONFIGURATOR_DATA = 'SET_CONFIGURATOR_DATA'
export const UPDATE_CONFIGURATOR_DATA = 'UPDATE_CONFIGURATOR_DATA'
export const UPDATE_CONFIGURATOR_BASKET = 'UPDATE_CONFIGURATOR_BASKET'
export const UPDATE_CONFIGURATOR_MEAL_TYPES = 'UPDATE_CONFIGURATOR_MEAL_TYPES'
export const UPDATE_CONFIGURATOR_PACKAGE = 'UPDATE_CONFIGURATOR_PACKAGE'
export const UPDATE_CONFIGURATOR_VARIANT = 'UPDATE_CONFIGURATOR_VARIANT'
export const UPDATE_CONFIGURATOR_DIET_LENGTH = 'UPDATE_CONFIGURATOR_DIET_LENGTH'
export const UPDATE_CONFIGURATOR_DAY_COST = 'UPDATE_CONFIGURATOR_DAY_COST'
export const UPDATE_CONFIGURATOR_WEEKENDS_INCLUDED =
  'UPDATE_CONFIGURATOR_WEEKENDS_INCLUDED'
export const UPDATE_CONFIGURATOR_NAME = 'UPDATE_CONFIGURATOR_NAME'
export const UPDATE_CONFIGURATOR_FIRST_DELIVERY_AT =
  'UPDATE_CONFIGURATOR_FIRST_DELIVERY_AT'
export const UPDATE_CONFIGURATOR_DISCOUNT_CODE =
  'UPDATE_CONFIGURATOR_DISCOUNT_CODE'
export const UPDATE_CONFIGURATOR_DISCOUNT_CODE_VALUE =
  'UPDATE_CONFIGURATOR_DISCOUNT_CODE_VALUE'
export const UPDATE_CONFIGURATOR_DISCOUNT_CODE_TYPE =
  'UPDATE_CONFIGURATOR_DISCOUNT_CODE_TYPE'
export const UPDATE_CONFIGURATOR_DISCOUNT_CODE_TEXT =
  'UPDATE_CONFIGURATOR_DISCOUNT_CODE_TEXT'
export const UPDATE_CONFIGURATOR_DISCOUNT_CODE_REFERER =
  'UPDATE_CONFIGURATOR_DISCOUNT_CODE_REFERER'
export const UPDATE_CONFIGURATOR_DISCOUNT_CODE_ERROR =
  'UPDATE_CONFIGURATOR_DISCOUNT_CODE_ERROR'
export const UPDATE_CONFIGURATOR_REFERER_CODE =
  'UPDATE_CONFIGURATOR_REFERER_CODE'
export const UPDATE_CONFIGURATOR_MGM_CODE = 'UPDATE_CONFIGURATOR_MGM_CODE'
export const CONFIGURATOR_OPTIONS_LOADED = 'CONFIGURATOR_OPTIONS_LOADED'
export const SET_DATA_FROM_LP = 'SET_DATA_FROM_LP'
export const UPDATE_ADDON_MEALS = 'UPDATE_ADDON_MEALS'
export const UPDATE_MEALS_PRICE = 'UPDATE_MEALS_PRICE'
export const UPDATE_SELECTED_DIET = 'UPDATE_SELECTED_DIET'
export const RESET_ADD_MEALS_STATE = 'RESET_ADD_MEALS_STATE'

// TODO: check if it used now, added new reset type per module, i.e. RESET_CONFIGURATOR
export const RESET_CONFIGURATOR_DATA = 'RESET_CONFIGURATOR_DATA'

/** Order **/
export const ORDER_CHANGE_FV = 'ORDER_CHANGE_FV'
export const ORDER_CHANGE_AGREEMENT = 'ORDER_CHANGE_AGREEMENT'
export const ORDER_CHANGE_NIP = 'ORDER_CHANGE_NIP'
export const ORDER_CHANGE_ERRORS = 'ORDER_CHANGE_ERRORS'
export const ORDER_CHANGE_LAST_ORDER = 'ORDER_CHANGE_LAST_ORDER'

/** Diet **/
export const DIET_SELECTED = 'DIET_SELECTED'

/** Moneybox **/
export const MONEYBOX_HISTORY_UPDATE = 'MONEYBOX_HISTORY_UPDATE'
export const MONEYBOX_HISTORY_UPDATE_CURRENT_PAGE =
  'MONEYBOX_HISTORY_UPDATE_CURRENT_PAGE'

/** Tags **/
export const UPDATE_TAG = 'UPDATE_TAG'

/** User **/
export const UPDATE_CLIENT_ADDRESSES = 'UPDATE_CLIENT_ADDRESSES'
export const UPDATE_CLIENT_OTHER_ADDRESSES = 'UPDATE_CLIENT_OTHER_ADDRESSES'
export const UPDATE_ORDER_STEP = 'UPDATE_ORDER_STEP'
export const UPDATE_ORDER_STEPS = 'UPDATE_ORDER_STEPS'
export const UPDATE_CLIENT_FULL_DATA = 'UPDATE_CLIENT_FULL_DATA'

/** Calendar **/
export const UPDATE_CALENDAR_SELECTED_BAG = 'UPDATE_CALENDAR_SELECTED_BAG'
export const UPDATE_CALENDAR_SELECTED_DAY = 'UPDATE_CALENDAR_SELECTED_DAY'
export const UPDATE_CALENDAR_VIEW_DATE = 'UPDATE_CALENDAR_VIEW_DATE'
export const UPDATE_ACTIVE_DAY = 'UPDATE_ACTIVE_DAY'
export const UPDATE_CALENDAR_INCREMENT_VIEW_DATE =
  'UPDATE_CALENDAR_INCREMENT_VIEW_DATE'
export const UPDATE_CALENDAR_DE_INCREMENT_VIEW_DATE =
  'UPDATE_CALENDAR_DE_INCREMENT_VIEW_DATE'
export const UPDATE_CALENDAR_CALENDAR = 'UPDATE_CALENDAR_CALENDAR'
export const UPDATE_BAG_DETAILS = 'UPDATE_BAG_DETAILS'
export const UPDATE_BAG_IS_LOADING_DETAILS = 'UPDATE_BAG_IS_LOADING_DETAILS'
export const UPDATE_BAG_DETAILS_SELECTED_MEAL =
  'UPDATE_BAG_DETAILS_SELECTED_MEAL'
export const UPDATE_BAG_DETAILS_MEAL_RATE = 'UPDATE_BAG_DETAILS_MEAL_RATE'
export const UPDATE_BAG_DETAILS_MEAL_COMMENT = 'UPDATE_BAG_DETAILS_MEAL_COMMENT'
export const UPDATE_CALENDAR_KEY = 'UPDATE_CALENDAR_KEY'
